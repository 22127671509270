// Node Modules
import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from 'react-markdown'
import CattegoryList from '../components/CategoryList'

// Components
import Layout from "../components/Layout/Layout";
import Seo from '../components/Seo';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Assets
import bgGrass from '../images/background-header-grass-70.svg'

const AttractionListPage = ({ data, location }) => {
  const page = data.pagedata;
  const pagesettings = data.attsettings.childMarkdownRemark.frontmatter;

  const divText = {
    gridArea: "1/1",
    position: "relative",
    placeItems: "end",
    display: "grid"
  };

  return (
    <>
      <Layout>
      <Seo strTitle={pagesettings.seotitle} strDescription={pagesettings.seometadesc} strImage={pagesettings.featuredimage.publicURL} />
        <div className="h-80 w-full md:h-425 lg:h-465 xl:h-515" style={{backgroundImage: `url(${bgGrass})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
            <div className="grid h-full relative overflow-hidden">
                <GatsbyImage image={pagesettings.featuredimage.childImageSharp.gatsbyImageData} alt={pagesettings.featuredimagealt}
                  className="row-start-1 col-start-1" style={{maxHeight: "515px", zIndex: "-10"}}
                  layout="fullWidth"
                  aspectratio={3 / 1}
                />
            </div>
        </div>
        <div className="">
            <div className="container mx-auto">
                <div style={divText} className="flex justify-around">
                    <div className="text-center font-cursive max-w-screen-lg">
                        <h1 className="text-template-accent tracking-wider text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-normal py-3">{pagesettings.title}</h1>
                    </div>
                </div>
                <div className="flex justify-around py-6 px-2">
                    <div className="max-w-screen-lg cmscontent" dangerouslySetInnerHTML={{ __html: data.attsettings.childMarkdownRemark.html}}>
                    </div>
                </div>
            </div>
            <CattegoryList location={location} />
            <div className="container mx-auto px-2">
                    <div className="overflow-hidden flex flex-wrap">
                    {page.edges.filter(edge => edge.node.frontmatter.production === true).map((edge, index) => {
                      const frontmatter = edge.node.frontmatter
                      const image = getImage(edge.node.frontmatter.featuredimage)
                      return (
                        <div className="xl:w-1/4 md:w-1/2 p-4 font-cursive" key={index}>
                        <div className="bg-gray-100 p-6 rounded-lg">
                          <GatsbyImage image={image} alt={frontmatter.featuredimagealt}
                            className="h-60 rounded w-full object-cover object-center mb-6"
                            layout="fullWidth"
                            aspectratio={3 / 1}
                          />
                          <div className="-mt-6 pr-1 text-right">
                            <small>
                              {frontmatter.featuredimagecredit &&(
                              frontmatter.featuredimagecredit
                              )}
                              {frontmatter.featuredimagecrediturl &&(
                                <span> - <a href={frontmatter.featuredimagecrediturl}  target="_blank" rel="noreferrer" aria-label="Credit Link">Link</a></span>
                              )}
                            </small>
                          </div>
                          <span className="tracking-widest text-xs font-medium text-template-accent">{frontmatter.category}</span>
                          <h2 className="text-lg text-gray-900 font-medium mb-4">{frontmatter.title}</h2>
                          <ReactMarkdown linkTarget="_blank" className="cmscontent">{frontmatter.description}</ReactMarkdown>
                        </div>
                      </div>
                      )
                    })}
                  </div>
</div>
        </div>
      </Layout>
    </>
  );
};

export default AttractionListPage;

export const query = graphql`
  query ($category: String, $skip: Int!, $limit: Int!) {
    attsettings: file(relativePath: {eq: "settings/attractions.md"}) {
      id
      childMarkdownRemark {
          html
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
              ext
              publicURL
            }
            featuredimagealt
            seotitle
            seometadesc
          }
      }
  }
    pagedata: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(attractions)/.*[.]md$/"}, frontmatter: {category: {in: [$category]}}}
      sort: { fields: [frontmatter___title], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            production
            title
            location
            description
            category
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
              ext
              publicURL
            }
            featuredimagealt
            featuredimagecredit
            featuredimagecrediturl
          }
        }
      }
    }
  }
`;
